<template>
    <Page title="Rent Run" icon="mdi-run" class-extra="mx-auto">
        <div class="constrain-width">
            <smart-form
                v-if="formData"
                :schema="formSchema"
                v-model="formData"
                @input="setGroupBy"
            />
            <toolbar
                :center-items="[
                    {
                        name: 'rentRunPreview',
                        label: 'Preview',
                        preset: 'yes',
                        onClick: doRentRunPreview,
                        size: 'x-small',
                    },
                    {
                        name: 'rentRunProcess',
                        label: 'Run',
                        preset: 'yes',
                        onClick: doRentRunProcess,
                        size: 'x-small',
                        confirm: 'u fkn sure?',
                    },
                ]"
            />
            <smart-form
                v-if="totals"
                :value="totals"
                :schema="totalsSchema"
            />
            <smart-table
                v-if="runItems"
                :items="runItems"
                :headers="runItemHeaders"
                :key="JSON.stringify(runItems)"
                item-key="leaseId"
                is-filter
                dense
                :group-by="formData.groupBy.id"
                :items-per-page="9999"
                :items-per-page-options="[9999]"
            >
                <template v-slot:group.header="{ items, isOpen, toggle }">
                    <th class="text-h6 primary">
                        <v-icon @click="toggle">
                            {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                        </v-icon>
                        {{ items[0].groupBy.text }}
                    </th>
                    <th class="text-h6 primary" colspan="2">{{ items.length }} Invoices</th>
                    <th class="text-right primary">
                        <currency :value="items[0].groupBy.amount" class="text-h6" show-zero />
                    </th>
                </template>
                <template v-slot:expanded-item="{ item }">
                    <td colspan="8">
                        <debug :value="item" />
                        <smart-table
                            :items="item.lines"
                            :headers="lineDetailHeaders"
                            dense
                            :items-per-page="9999"
                            :items-per-page-options="[9999]"
                        />
                    </td>
                </template>
                <template v-slot:item.action="{ item }">
                    <h5>xxxx</h5>
                </template>
            </smart-table>
        </div>
    </Page>
</template>
<script>
import api from '@/api';

import dateUtil from '@/../common/utils/date';
import mathUtil from '@/../common/utils/math';
import objectUtil from '@/../common/utils/object';
import sortUtil from '@/../common/utils/sort';
import Currency from '@/components/format/Currency';

const runItemHeaders = [
    {
        text: 'Customer',
        align: 'left',
        sortable: true,
        value: 'customerCode',
    },
    {
        text: 'Property',
        align: 'left',
        sortable: true,
        value: 'propertyCode',
    },
    {
        text: 'Areas',
        align: 'left',
        sortable: true,
        value: 'areas',
    },
    {
        text: 'Amount',
        align: 'right',
        sortable: true,
        value: 'documentTotal',
        customComponent: 'Currency',
        showZero: true,
    },
];

const lineDetailHeaders = [
    {
        text: 'Item',
        align: 'left',
        value: 'itemCode',
    },
    {
        text: 'Tax',
        align: 'center',
        value: 'taxStatus',
    },
    {
        text: 'startDate',
        align: 'left',
        value: 'Start',
    },
    {
        text: 'endDate',
        align: 'left',
        value: 'End',
    },
    {
        text: 'Days In Month',
        align: 'left',
        value: 'daysInMonth',
    },
    {
        text: 'Revision Days',
        align: 'left',
        value: 'areas',
    },
    {
        text: 'Percentage',
        align: 'left',
        value: 'areas',
    },
    {
        text: 'Line Start',
        align: 'left',
        value: 'lineStartDate',
    },
    {
        text: 'Line End',
        align: 'left',
        value: 'lineEndDate',
    },
    {
        text: 'Interval',
        align: 'left',
        value: 'rentInterval',
    },
    {
        text: 'Amount',
        align: 'right',
        sortable: true,
        value: 'amount',
        customComponent: 'Currency',
        showZero: true,
    },
    {
        text: 'Line Amount',
        align: 'right',
        sortable: true,
        value: 'lineAmount',
        customComponent: 'Currency',
        showZero: true,
    },
];

const formSchema = {
    yearMonth: {
        component: 'date-field',
        label: 'Month',
        cols: 4,
        today: true,
        month: true,
    },
    groupBy: {
        component: 'select-field',
        items: [
            { id: 'companyCode', text: 'Company' },
            { id: 'propertyDescription', text: 'Property' },
        ],
        label: 'Totals By',
        returnObject: true,
        cols: 4,
    },
    blank1: {
        component: 'blank-field',
        cols: 4,
    },
    companyLink: {
        component: 'text-field',
        autocomplete: {
            type: 'company',
        },
        label: 'Company',
        cols: 4,
        clearable: true,
        returnObject: true,
        hideNoData: true,
    },
    customerLink: {
        component: 'text-field',
        autocomplete: {
            type: 'customer',
            filterCompanyLink: true,
        },
        monitorFields: ['companyLink'],
        label: 'Customer',
        cols: 4,
        clearable: true,
        returnObject: true,
        hideNoData: true,
    },
    propertyLink: {
        component: 'text-field',
        autocomplete: {
            type: 'property',
            filterCompanyLink: true,
            filterCustomerLink: true,
        },
        monitorFields: ['companyLink', 'customerLink'],
        label: 'Property',
        cols: 4,
        clearable: true,
        returnObject: true,
        hideNoData: true,
    },
};

const totalsSchema = {
    rentRunTotal: { component: 'currency-field', readOnly: true, label: 'Total', cols: 4 },
    rentRunCount: {
        component: 'currency-field',
        readOnly: true,
        label: 'Invoice Count',
        cols: 4,
        precision: 0,
    },
};

export default {
    components: { Currency },
    data: () => ({
        formSchema,
        formData: null,
        runItemHeaders,
        runItems: null,
        totals: null,
        totalsSchema,
        lineDetailHeaders,
    }),
    methods: {
        setGroupBy() {
            console.log('setGroupBy!!! ', this.formData);
            let companyGroupBy = {};
            let propertyGroupBy = {};

            this.runItems = (this.runItems || [])
                .map((oneItem) => {
                    companyGroupBy[oneItem.companyCode] = mathUtil.round(
                        (companyGroupBy[oneItem.companyCode] || 0) + (oneItem.documentTotal || 0),
                    );
                    propertyGroupBy[oneItem.propertyCode] = mathUtil.round(
                        (propertyGroupBy[oneItem.propertyCode] || 0) + (oneItem.documentTotal || 0),
                    );
                    return oneItem;
                })
                .map((oneItem) => ({
                    ...oneItem,
                    propertyDescription: `${oneItem.companyCode} ${oneItem.propertyCode}`,
                    groupBy:
                        this.formData.groupBy.id === 'companyCode'
                            ? {
                                  text: oneItem.companyCode,
                                  amount: companyGroupBy[oneItem.companyCode],
                              }
                            : {
                                  text: `${oneItem.companyCode} ${oneItem.propertyCode}`,
                                  amount: propertyGroupBy[oneItem.propertyCode],
                              },
                    searchFields: `${oneItem.companyCode} ${oneItem.customerCode} ${oneItem.propertyCode} ${oneItem.documentTotal} `,
                }));

            this.runItems = sortUtil(this.runItems, {
                companyCode: 1,
                customerCode: 1,
                propertyCode: 1,
            });
        },
        async doRentRun(isProcessInvoices) {
            this.$store.dispatch(
                'setLoading',
                isProcessInvoices ? 'Generating Rent and Posting Invoices' : 'Calculating Rent',
            );

            console.log('doRentRun', isProcessInvoices);
            const { yearMonth, companyLink, customerLink, propertyLink } = this.formData;

            const result = await api.post(this.$store, `rent`, {
                yearMonth,
                companyLink,
                customerLink,
                propertyLink,
                isProcessInvoices,
            });

            console.log('return from api', result);
            console.log('oneItem', result.data.runItems[0]);

            this.runItems = result.data.runItems.map((oneItem) => {
                const lines = oneItem.rentPeriodBreakup.rent.lines.map((oneLine) => ({
                    itemCode: oneLine.itemLink.text,
                    extaxAmount: oneLine.extaxAmount,
                    taxStatus: oneLine.taxStatus,
                    startDate: oneLine.startDate,
                    endDate: oneLine.endDate,
                    daysInMonth: oneLine.daysInMonth,
                    revisionDays: oneLine.revisionDays,
                    revisionPercentage: mathUtil.round(oneLine.revisionProportion * 100),
                    lineStartDate: oneLine.lineStartDate,
                    lineEndDate: oneLine.lineEndDate,
                    rentInterval: oneItem.rentPeriodBreakup.rent.leaseDetails.rentInterval,
                    amount: oneLine.amount,
                    lineAmount: oneLine.lineAmount,
                }));

                return {
                    leaseId: objectUtil.getObjectFieldByString(
                        'rentPeriodBreakup.lease.id',
                        oneItem,
                    ),
                    companyCode: objectUtil.getObjectFieldByString(
                        'document.companyLink.data.companyCode',
                        oneItem,
                    ),
                    customerCode: objectUtil.getObjectFieldByString(
                        'document.customerLink.data.customerCode',
                        oneItem,
                    ),
                    propertyCode: objectUtil.getObjectFieldByString(
                        'rentPeriodBreakup.rent.propertyLink.data.propertyCode',
                        oneItem,
                    ),
                    documentTotal: objectUtil.getObjectFieldByString(
                        'document.documentTotal',
                        oneItem,
                    ),
                    areas: (
                        objectUtil.getObjectFieldByString(
                            'rentPeriodBreakup.lease.areas',
                            oneItem,
                        ) || []
                    )
                        .map((oneArea) => oneArea.areaCode)
                        .join(', '),
                    // document: oneItem.document,
                    // rentPeriodBreakup: oneItem.rentPeriodBreakup,
                    lines,
                };
            });
            // .map(oneItem => {
            //     companyGroupBy[oneItem.companyCode] = mathUtil.round(
            //         (companyGroupBy[oneItem.companyCode] || 0) + (oneItem.documentTotal || 0),
            //     );
            //     propertyGroupBy[oneItem.propertyCode] = mathUtil.round(
            //         (propertyGroupBy[oneItem.propertyCode] || 0) + (oneItem.documentTotal || 0),
            //     );
            //     return oneItem;
            // })
            // .map(oneItem => ({
            //     ...oneItem,
            //     groupBy:
            //         this.formData.groupBy.id === 'companyCode'
            //             ? {
            //                   text: oneItem.companyCode,
            //                   amount: companyGroupBy[oneItem.companyCode],
            //               }
            //             : {
            //                   text: `${oneItem.companyCode} ${oneItem.propertyCode}`,
            //                   amount: propertyGroupBy[oneItem.propertyCode],
            //               },
            //     searchFields: `${oneItem.companyCode} ${oneItem.customerCode} ${
            //         oneItem.propertyCode
            //     } ${oneItem.documentTotal} `,
            // }));

            this.setGroupBy();

            console.log('about to buildTotals', this.runItems[0]);
            this.buildTotals();
            this.$store.dispatch('setLoading');
        },
        async doRentRunPreview() {
            return await this.doRentRun(false);
        },
        async doRentRunProcess() {
            return await this.doRentRun(true);
        },
        async getFormData() {
            const todayString = dateUtil.getTodayString();
            const nextMonth = dateUtil.callDateFns('addMonths', todayString, 1);

            this.formData = {
                yearMonth: nextMonth,
                groupBy: { id: 'companyCode' },
                companyLink: { id: '6050125c584925c9ec2a3a1c', text: 'NEW Newllen Pty Ltd' },
            };
        },
        buildTotals() {
            const { rentRunTotal, rentRunCount } = this.runItems.reduce(
                (accum, oneRunItem) => {
                    const rentRunTotal = mathUtil.round(
                        accum.rentRunTotal + (oneRunItem.documentTotal || 0.0),
                    );
                    const rentRunCount = accum.rentRunCount + 1;
                    return { rentRunTotal, rentRunCount };
                },
                { rentRunTotal: 0.0, rentRunCount: 0 },
            );

            this.totals = { rentRunTotal, rentRunCount };
        },
    },
    async mounted() {
        await this.getFormData();
    },
};
</script>
