import objectUtil from './object';

const percent = value => value / 100;

const gstRate = percent(10);

const randomNumberString = length => {
    const randomNo = `${Math.round(Math.random() * 10 ** length)}`;
    return `000000000000000000${randomNo}`.substr(-1 * length);
};
export const round = (decimalValue, decimalPlaces = 2) => {
    const useDecimalNumber = parseFloat(`${decimalValue}`);
    return Math.round(useDecimalNumber * 10 ** decimalPlaces) / 10 ** decimalPlaces;
};
const calcExGSTAmount = amountIncludingGST => round(amountIncludingGST / (1 + gstRate));

const calcIncGSTAmount = amountExcludingGST => round(amountExcludingGST * (1 + gstRate));

const calcGst = (sourceArray, isExtax = true) =>
    sourceArray.reduce(
        (accum, amount) => round(accum + (isExtax ? amount * gstRate : amount - calcExGSTAmount(amount))),
        0.0,
    );

const calcArrayTotal = (sourceArray, fieldName, decimalPlaces) =>
    round(
        sourceArray.reduce((accum, oneItem) => accum + objectUtil.getObjectFieldByString(fieldName, oneItem), 0),
        decimalPlaces,
    );

export default {
    calcGst,
    gstRate,
    randomNumberString,
    calcExGSTAmount,
    calcIncGSTAmount,
    calcArrayTotal,
    round,
};

// const getAmountAndGST = (originalAmount) => {
//
//     const examount = originalAmount/1.1
//     const gst = examount *.1
//
//     if gst + examount !== originalAmount
//     examount +=0.01
//
//
//     return {
//         exAmount,
//         gst
//     }
// }
// const lines = [
//     { lineAmount: 101.23, taxStatus: 'G' },
//     { lineAmount: 80, taxStatus: 'G' },
//     { lineAmount: 50, taxStatus: 'F' },
// ];
//
// console.info(calcGst(lines.filter(line => line.taxStatus === 'G').map(line => line.lineAmount)));
