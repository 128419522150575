var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Page',{attrs:{"title":"Rent Run","icon":"mdi-run","class-extra":"mx-auto"}},[_c('div',{staticClass:"constrain-width"},[(_vm.formData)?_c('smart-form',{attrs:{"schema":_vm.formSchema},on:{"input":_vm.setGroupBy},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}}):_vm._e(),_c('toolbar',{attrs:{"center-items":[
                {
                    name: 'rentRunPreview',
                    label: 'Preview',
                    preset: 'yes',
                    onClick: _vm.doRentRunPreview,
                    size: 'x-small',
                },
                {
                    name: 'rentRunProcess',
                    label: 'Run',
                    preset: 'yes',
                    onClick: _vm.doRentRunProcess,
                    size: 'x-small',
                    confirm: 'u fkn sure?',
                },
            ]}}),(_vm.totals)?_c('smart-form',{attrs:{"value":_vm.totals,"schema":_vm.totalsSchema}}):_vm._e(),(_vm.runItems)?_c('smart-table',{key:JSON.stringify(_vm.runItems),attrs:{"items":_vm.runItems,"headers":_vm.runItemHeaders,"item-key":"leaseId","is-filter":"","dense":"","group-by":_vm.formData.groupBy.id,"items-per-page":9999,"items-per-page-options":[9999]},scopedSlots:_vm._u([{key:"group.header",fn:function({ items, isOpen, toggle }){return [_c('th',{staticClass:"text-h6 primary"},[_c('v-icon',{on:{"click":toggle}},[_vm._v(" "+_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus')+" ")]),_vm._v(" "+_vm._s(items[0].groupBy.text)+" ")],1),_c('th',{staticClass:"text-h6 primary",attrs:{"colspan":"2"}},[_vm._v(_vm._s(items.length)+" Invoices")]),_c('th',{staticClass:"text-right primary"},[_c('currency',{staticClass:"text-h6",attrs:{"value":items[0].groupBy.amount,"show-zero":""}})],1)]}},{key:"expanded-item",fn:function({ item }){return [_c('td',{attrs:{"colspan":"8"}},[_c('debug',{attrs:{"value":item}}),_c('smart-table',{attrs:{"items":item.lines,"headers":_vm.lineDetailHeaders,"dense":"","items-per-page":9999,"items-per-page-options":[9999]}})],1)]}},{key:"item.action",fn:function({ item }){return [_c('h5',[_vm._v("xxxx")])]}}],null,false,3462100071)}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }